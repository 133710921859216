<template>
  <b-container fluid class="px-sm-5 position-relative index-1">
    <b-row class="no-gutters mt-4 pb-4">
      <b-col col md="5" class="align-self-center">
        <h1 class="color-white line-height-1">RATify | <small><small>AI ASSURANCE ORCHESTRATOR</small></small></h1>
        <span class="border-separator"></span>
        <h4 class="color-white mb-4">
          Be part of the movement to democratise healthcare.
        </h4>
        <b-form @submit.prevent="submit">
          <b-form-group id="name-group" label-for="name">
            <b-form-input
              v-model="user.username"
              id="form_name"
              type="text"
              required
              placeholder="Please enter your email or mobile number"
              data-error="Email or mobile number is required."
            ></b-form-input>
          </b-form-group>
          <b-form-group id="email-group" label-for="email">
            <b-form-input
              v-model="user.password"
              id="form_password"
              type="password"
              required
              placeholder="Please enter your password"
            ></b-form-input>
          </b-form-group>
          <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
          <b-button :disabled="formStatus==='submitting'" type="submit" pill class="btn-yellow mb-4 px-5">Proceed</b-button>
          <b-button :disabled="formStatus==='submitting'" type="button" pill class="btn-blue ml-2 mb-4 px-4" @click.prevent="getOpenIdUrl()">3<sup>rd</sup> Party Login</b-button>
        </b-form>
        <p class="color-white">
          <strong class="color-white d-block mb-2">CONTACT US</strong>Should you
          have any feedback, comments, requests for technical support or other
          inquiries, please contact us by email:
          <a href="mailto:info@envisionit.ai">info@envisionit.ai</a>.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { authService } from '../../services'
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      formStatus: null,
      user: {
        username: null,
        password: null,
        recaptcha: null
      }
    }
  },
  computed: {
    ...mapState({
      status: state => state.auth.status,
      alert: state => state.alert
    })
  },
  created () {
    this.logout()
  },
  methods: {
    ...mapActions('alert', ['error']),
    ...mapActions('auth', ['login', 'logout']),
    submit: function () {
      this.formStatus = 'submitting'
      this.login(this.user)
    },
    getOpenIdUrl: async function () {
      try {
        const url = await authService.getOpenId('admin')
        if (url && url.url) {
          window.location.href = url.url
        } else {
          this.error('Could not get 3rd party authentication web addresses. Please, try again later ...')
        }
      } catch (error) {
        // console.log(error)
        this.error('Could not invoke 3rd party authentication protocol. Please, try again later ...')
      }
    }
  },
  watch: {
    'user.username' () {
      this.formStatus = null
    },
    'user.password' () {
      this.formStatus = null
    }
  }
}
</script>
